<template>
  <v-row>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-col cols="12" sm="4" lg="2">
      <h4>Dienstleister Kategorien
        <v-tooltip right color="" max-width="200px">
          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="d-inline-flex" size="20">
                          mdi-help
                        </v-icon>
                      </span>
          </template>
          Lege die Kategorien fest, in die du deine Dienstleister einordnest.
        </v-tooltip>
      </h4>
    </v-col>
    <v-col>
      <div v-for="category of categories" :key="category.id" class="pb-3">
        <v-icon v-if="category.icon">
          {{ category.icon }}
        </v-icon>
        {{ category.name }}
        <v-tooltip right color="red">
          <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                      <v-icon @click="sureToDeleteDialog = true;  selectedId = category.id">
                      mdi-delete
                      </v-icon>
                        </span>
          </template>
          Status löschen
        </v-tooltip>

      </div>
      <SureToDelete
          class="mt-0 pt-0 mb-0 pb-0"
          :dialog="sureToDeleteDialog"
          :text="'Bist du Sicher, dass du die Kategorie unwiderruflich löschen möchtest?'"
          @cancel="sureToDeleteDialog = false"
          @sure="deleteCategory(); sureToDeleteDialog = false"
      ></SureToDelete>
      <v-row class="align-center">
        <v-col cols="8" md="6">
          <v-row>
            <v-col v-if="icon" cols="1" class="pt-8">
              <v-icon class="d-inline">
                {{ icon }}
              </v-icon>
            </v-col>
            <v-col class="pt-8" cols="2">
              <a v-if="!chooseIcon" @click="chooseIcon=true">Icon</a>
            </v-col>

            <v-col>
              <v-text-field placeholder="Neue Kategorie" v-model="newCategory"></v-text-field>
            </v-col>
          </v-row>
          <a v-if="chooseIcon" @click="chooseIcon=false; icon=''">Icon entfernen</a>


        </v-col>

        <v-col cols="4" md="6">

       <a   @click="addVendorCategory(newCategory)" class="d-inline">
          Hinzufügen
        </a>
        </v-col>
      </v-row>
      <div v-if="chooseIcon">
        <IconPicker direction="left" text="" v-model="icon"></IconPicker>
      </div>
    </v-col>
  </v-row>
</template>

<script>

import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const IconPicker = () => import("@/components/generalUI/IconPicker");
const Message = () => import("@/components/generalUI/Message");
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "VendorCategory",
  computed: {
    ...mapGetters('settings', {
      categories: 'categories',
    }),
  },
  components:{
    Message,
    SureToDelete,
    IconPicker
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  mounted() {
    this.$store.dispatch('settings/getCategories', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    })
  },
  data() {
    return {
      newCategory: '',
      icon: "",
      chooseIcon: false,
      message: msgObj(),
      sureToDeleteDialog:false,
      selectedId:""
    }
  },
  methods: {
    addVendorCategory(name) {
      this.$store.dispatch('settings/addVendorCategory', {
        uid: this.user.id,
        name,
        icon: this.icon
      }).then(() => {
        this.newCategory = ''
      }).catch((err) => {
        this.message = error(err)
      })
    },
    deleteCategory() {
      this.$store.dispatch('settings/deleteCategory', {
        uid: this.user.id,
        id: this.selectedId,
      }).then(() => {
        this.message = success('Kategorie erfolgreich gelöscht.')
      }).catch((err) => {
        this.message = error(err)
      })
    },
  }

}
</script>
